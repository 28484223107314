/*
Replace
menuDish with your resource Name :) Take care about different writings!
/!\ MenuDish menuDish MenuDishes menuDishes /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultRemoveSuccess,
  defaultRemoveFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";
import { getDishAttributes } from "../dishes";
import { getCounterAttributes } from "../counters";
import { getLocationAttributes } from "../locations";
import { getTimeslotAttributes } from "../timeslots";

export const menuDishSlice = createSlice({
  name: "menuDishes",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    remove: (state, action) => {
      state.removing = true;
      state.removed = false;
      state.removeError = null;
      state.list = state.list.filter(
        (entry) => entry.id !== action.payload.menuDishId
      );
      delete state.dictionary[action.payload.menuDishId];
    },
    removeSuccess: defaultRemoveSuccess,
    removeFail: defaultRemoveFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listMenuDishes,
  show: showMenuDish,
  create: createMenuDish,
  update: updateMenuDish,
  remove: removeMenuDish,
} = menuDishSlice.actions;

export const actions = menuDishSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const menuDishes = (state) =>
  state.menuDishes.idsList.map((id) => state.menuDishes.dictionary[id]);
export const menuDish = (state) =>
  state.menuDishes.showId &&
  state.menuDishes.dictionary[state.menuDishes.showId];
export const menuDishAttributes = (state) => menuDish(state)?.attributes;
export const menuDishRelationships = (state) => menuDish(state)?.relationships;
export const menuDishId = (state) => state.menuDishes.showId;

export const getMenuDish = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getMenuDishAttributes = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getMenuDishRelationships = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getMenuDishAttributesExpanded = createSelector(
  (state, id) => getMenuDish(state, id),
  (state, id) => {
    const relationships = getMenuDishRelationships(state, id);
    if (relationships) {
      return {
        dish: getDishAttributes(state, relationships.dish.data.id),
        counter: getCounterAttributes(state, relationships.counter.data.id),
        location: getLocationAttributes(state, relationships.location.data.id),
        timeslot: getTimeslotAttributes(state, relationships.timeslot.data.id),
      };
    }
  },
  (menuDish, relatedAttributes) =>
    menuDish &&
    relatedAttributes && { ...menuDish.attributes, ...relatedAttributes }
);

export const getMenuDishes = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getMenuDishesForDate = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, day) => day,
  (dictionary, day) => {
    return Object.entries(dictionary)
      .filter((_menuDish) => _menuDish[1].attributes.date_of_serving === day)
      .map((_menuDish) => _menuDish[1]);
  }
);

export const getMenuDishIdsForDate = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, day) => day,
  (dictionary, day) => {
    return Object.entries(dictionary)
      .filter((_menuDish) => _menuDish[1].attributes.date_of_serving === day)
      .map((_menuDish) => _menuDish[0]);
  }
);

export const getMenuDishIdsForDateTimeslotAndCounter = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, singleDayId) => singleDayId,
  (_, timeslotId) => timeslotId,
  (_, counterId) => counterId,
  (dictionary, { singleDayId, timeslotId, counterId }) => {
    return Object.entries(dictionary)
      .filter(
        (_menuDish) =>
          _menuDish[1].attributes.date_of_serving === singleDayId &&
          _menuDish[1].relationships.timeslot.data.id === timeslotId &&
          _menuDish[1].relationships.counter.data.id === counterId
      )
      .map((_menuDish) => _menuDish[0]);
  }
);

export const getMenuDishIdsForDateAndCounter = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, singleDayId) => singleDayId,
  (_, counterId) => counterId,
  (dictionary, { singleDayId, counterId }) => {
    return Object.entries(dictionary)
      .filter(
        (_menuDish) =>
          _menuDish[1].attributes.date_of_serving === singleDayId &&
          _menuDish[1].relationships.counter.data.id === counterId
      )
      .map((_menuDish) => _menuDish[0]);
  }
);

// getDish(state, parseInt(menuDish?.relationships?.dish?.data?.id)

export const getMenuDishRecipeIngredientIds = createSelector(
  (state) => state.menuDishes.dictionary,
  (state) => state.dishes.dictionary,
  (state) => state.recipes.dictionary,
  (state) => state.dishRecipeIngredients.dictionary,
  (_, id) => id,
  (
    dictionary,
    dishesDictonary,
    recipeDictionary,
    dishRecipeIngredientDictionary,
    id
  ) => {
    const dishRecipeIngredientIds = [];

    const menuDish = dictionary[id];
    const dish = dishesDictonary[menuDish?.relationships?.dish?.data?.id];
    const recipe = recipeDictionary[dish?.attributes?.main_recipe_id];
    if (menuDish && dish && recipe) {
      recipe?.relationships.dish_recipe_ingredients.data.forEach((dri) => {
        if (dishRecipeIngredientDictionary[dri.id]) {
          dishRecipeIngredientIds.push(dri.id);
        }
      });
    }
    return dishRecipeIngredientIds;
  }
);

export default menuDishSlice.reducer;
