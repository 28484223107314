/*
Replace
menuDishRecipeIngredient with your resource Name :) Take care about different writings!
/!\ DishRecipeIngredient menuDishRecipeIngredient DishRecipeIngredients menuDishRecipeIngredients /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
  defaultRemoveSuccess,
  defaultRemoveFail,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const todoSlice = createSlice({
  name: "todos",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    remove: (state, action) => {
      state.removing = true;
      state.removed = false;
      state.removeError = null;
      state.list = state.list.filter(
        (entry) => entry.id !== action.payload.todoId
      );
      delete state.dictionary[action.payload.todoId];
    },
    removeSuccess: defaultRemoveSuccess,
    removeFail: defaultRemoveFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listTodos,
  show: showTodo,
  create: createTodo,
  update: updateTodo,
  remove: removeTodo,
} = todoSlice.actions;

export const actions = todoSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const todos = (state) =>
  Object.keys(state.todos.dictionary).map((id) => state.todos.dictionary[id]);
export const todo = (state) =>
  state.todos.showId && state.todos.dictionary[state.todos.showId];
export const todoAttributes = (state) => todo(state)?.attributes;
export const todoRelationships = (state) => todo(state)?.relationships;
export const todoId = (state) => state.todos.showId;

export const getTodo = createSelector(
  (state) => state.todos.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getChildTodoIds = createSelector(
  (state) => state.todos.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return Object.entries(dictionary)
      .filter((todo) => todo[1].attributes.parent_id === parseInt(id))
      .map((todo) => todo[0]);
  }
);

export const getChildTodos = createSelector(
  (state) => state.todos.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return Object.entries(dictionary)
      .filter((todo) => todo[1].attributes.parent_id === parseInt(id))
      .map((todo) => dictionary[todo[0]]);
  }
);

export const getTodoAttributes = createSelector(
  (state) => state.recipes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getByMenuDishId = createSelector(
  [(state) => state.todos.dictionary, (_, menuDishId) => menuDishId],
  (dictionary, menuDishId) => {
    return Object.entries(dictionary)
      .filter(
        (todo) => todo[1].attributes.menu_dish_id === parseInt(menuDishId)
      )
      .map((todo) => dictionary[todo[0]]);
  }
);

export const getByMenuDishRecipeIngredient = createSelector(
  [
    (state) => state.todos.dictionary,
    (_, dishRecipeIngredientId) => dishRecipeIngredientId,
    (_, _dishRecipeIngredientId, menuDishId) => menuDishId,
  ],
  (dictionary, dishRecipeIngredientId, menuDishId) => {
    return Object.entries(dictionary).find(
      (_menuDishRecipeIngredient) =>
        _menuDishRecipeIngredient[1].attributes.menu_dish_id === menuDishId &&
        _menuDishRecipeIngredient[1].attributes.doable_type ===
          "DishRecipeIngredient" &&
        _menuDishRecipeIngredient[1].attributes.doable_id ===
          dishRecipeIngredientId
    )?.[1];
  }
);

export default todoSlice.reducer;
