export const translations = {
  'de': {
    'Allergens': 'Allergene',
    'No Allergenes': 'Keine Allergene',
    'loading': 'Lade',
    'Error fetching data': 'Fehler beim laden',
    'This course currently has no lessons': 'Diese Kurse hat keine Lektion',
    'Week': 'Woche',
    'Available Toppings:': 'Verfügbare Toppings:',
    'Season Warning': 'Dieses Gericht enthält die folgenden Zutaten, die im {month} in der Nebensaison sind',
    'Plan Dish': 'Gericht planen',
    'Start Cooking': 'Zubereiten',
    'Details': 'Details',
    'Choose New Dish': 'Neues Gericht wählen',
    'Delete': 'Löschen',
    'Delete Dish': 'Gericht löschen',
    'Are you sure you want to delete this dish?': 'Bist du sicher, dass du dieses Gericht löschen willst?',
    'Cancel': 'Abbrechen',
    'Loading': 'Lade',
    'All planed': 'Alles verplant',
    'Other recipes': 'Aus anderen Rezepten',
    'Choose Dish': 'Gericht wählen',
    'Topping 1': 'Topping 1',
    'Topping 2': 'Topping 2',
    'Information': 'Informationen',
    'Load complete list': 'Ges. Liste laden',
    'Not enough data yet.': 'Noch nicht genug Daten erhoben.',
    'Feedback total': 'Feedback gesamt',
    'Ratings': 'Bewertungen',
    'Privacy Policy': 'Datenschutzerklärung',
    'Back': 'Zurück',
    'Forward': 'Weiter',
    'watch': 'Ansehen',
    'Manager Contact': 'Kontakt Manager',
    'Plan Menu': 'Menü Planen',
    'Email': 'E-Mail',
    'required': 'Pflichtfeld',
    'Password': 'Passwort',
    'Login': 'Login',
    'Forgot your password? Recover here': 'Passwort Vergessen',
    'Here': 'Hier',
    'Dashboard': 'Dashboard',
    'Menu Planner': 'Menü Planer',
    'Todo Planner': 'Todo Planer',
    'Todos': 'To-dos',
    'User Management': 'Benutzer',
    'Academy': 'Akademie',
    'Profile': 'Profil',
    'Users': 'Users',
    'Locations': 'Standorte',
    'Logout': 'Logout',
    'Register': 'Anmelden',
    'Ready to cook?': 'Bereit zum kochen?',
    'Dish Overview': 'Überblick',
    'All steps': 'Gericht anrichten',
    'Ingredients': 'Zutaten',
    'Toppings': 'Toppings',
    'This dish does not have any toppings': 'Dieses Gericht hat keine Toppings',
    'Offline Message': 'Du bist Offline :(',
    'Submit': 'Speichern',
    'Password (confirmation)': 'Passwort-Wiederholung',
    'Servings': 'Portionen',
    'Serving': 'Portionen',
    'Basis': 'Basis',
    'Dish': 'Gericht',
    'No ingredients available': 'Keine Zutaten verfügbar',
    'Steps': 'Schritte',
    'Step': 'Schritt',
    'Previous Step': 'Vorheriger Schritt',
    'Done': 'Erledigt',
    'Next Step': 'Nächster Schritt',
    'Name': 'Name',
    'Season Warning {month}': 'Dieses Gericht enthält die folgenden Zutaten, die im {month} in der Nebensaison sind',
    'Season Confirmation {dish}': 'Möchten Sie eine {dish} planen?',
    'collapse': 'Einklappen',
    'expand': 'Ausklappen',
    'Shopping List': 'Einkaufsliste',
    'Shopping List Wizzard': 'Einkaufsliste exportieren',
    'shopping list data fetch failed': 'Fehler beim Laden der Daten. Bitte probiere es noch einmal später',
    'Days': 'Tage',
    'Counters': 'Counter',
    'Ingredient groups': 'Zutatengruppen',
    'active ingredient groups': 'Zutatengruppe(n) gewählt',
    'Settings': 'Einstellungen',
    'Combine ingredients': 'Zutaten zusammenfassen',
    'Include metadata': 'Metadaten anzeigen',
    'Download as CSV': 'Als CSV laden',
    'date_of_serving': 'Datum',
    'ingredient_id': 'Zutaten Id',
    'name': 'Zutat',
    'required_amount': 'Menge',
    'required_amount_measurement_unit': 'Einheit',
    'recipe_name': 'Rezept',
    'recipe_id': 'Rezept Id',
    'dish_id': 'Gericht Id',
    'dish_name': 'Gericht',
    'menu_dish_id': 'Gericht Id',
    'producer': 'Hersteller',
    'brand': 'Marke',
    'unit_size': 'Größe',
    'product_measurement_unit': 'Messeinheit',
    'required_units': 'Einheit',
    'counter_name': 'Counter',
    'location': 'Standort',
    'mbs5_number': 'MBS 5',
    'ingredient_group_name': 'Zutatengruppen',
    'ingredient_group_id': 'Zutatengruppen Id',
    'today': 'Heute',
    'Total amount': 'Total',
    'User': 'Benutzer',
    'All Professional Users': 'Alle Nutzer',
    'Due date': 'Datum',
    'Add Todo': 'To-do hinzufügen',
    'Language': 'Sprache',
    'save': 'Speichern',
    'Unassigned': 'Nicht zugeordnet',
    'Show': 'Anzeigen',
    'Courses': 'Kurse',
    'Hello {name}': 'Hallo, {name}!',
    'Nothing planed yet': 'Noch kein Gericht für heute geplant',
    'Feedback': 'Feedback',
    'CO2 Saved': 'CO₂e eingespart',
    'Organic Garden News': 'Organic Garden News',
    'ForOFour.message': 'Seite wurde nicht gefunden',
    'ForOFour.button': 'Zurück',
    'There currently are no chapters in this lesson.': 'Diese Kurse hat keine Lektion.',
    'You\'ve finished the lesson': 'Du hast die Lektion abgeschlossen',
    'You got': 'Du hast',
    'answers right': 'Frage(n) richtig beantwortet',
    'Back to Lessons': 'Zurück zu Lektionen',
    'Your Locations': 'Deine Standorte',
    'CW': 'KW',
    'Dish Bundles': 'Menülinie',
    'Recipe Types': 'Ernährungsform',
    'Allergies': 'Allergien',
    'Sort By': 'Sortieren nach',
    'Search': 'Suche',
    'Show More': 'Mehr anzeigen',
    'Today': 'Heute',
    'Overview': 'Überblick',
    'Your todos': 'To-dos',
    'Unassigned components': 'Nicht zugeordnet Komponenten',
    'Please choose a todo': 'Bitte wähle ein To-do aus',
    'Nickname': 'Nickname',
    'Role': 'Rolle',
    'Edit': 'Bearbeiten',
    'User updated successfully!': 'User erfolgreich aktualisiert!',
    'There was an error processing your request.': 'Es ist ein Fehler bei der Bearbeitung deiner Anfrage aufgetreten.',
    'Save': 'Speichern',
    'Invitation sent successfully!': 'Einladung erfolgreich gesendet!',
    'There was an error processing your request or this user has already been invited.': 'Es ist ein Fehler bei der Bearbeitung deiner Anfrage aufgetreten oder dieser Benutzer wurde bereits eingeladen.',
    'Invite User': 'User einladen',
    'Location': 'Standort',
    'Username': 'Benutzer',
    'Your Users': 'Deine Users',
    'Sunday': 'Sonntag',
    'Monday': 'Montag',
    'Tuesday': 'Dienstag',
    'Wednesday': 'Mittwoch',
    'Thursday': 'Donnerstag',
    'Friday': 'Freitag',
    'Saturday': 'Samstag',
    'Su': 'So',
    'Mo': 'Mo',
    'Tu': 'Di',
    'We': 'Mi',
    'Th': 'Do',
    'Fr': 'Fr',
    'Sa': 'Sa',
    'January': 'Januar',
    'February': 'Feburar',
    'March': 'März',
    'April': 'April',
    'May': 'Mai',
    'June': 'Juni',
    'July': 'Juli',
    'August': 'August',
    'September': 'September',
    'October': 'Oktober',
    'November': 'November',
    'December': 'Dezember',
    'Jan': 'Jan',
    'Feb': 'Feb',
    'Mar': 'Mär',
    'Apr': 'Apr',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Aug',
    'Sep': 'Sep',
    'Oct': 'Okt',
    'Nov': 'Nov',
    'Dec': 'Dez',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Allergens': 'Allergens',
    'None': 'None',
    'This course currently has no lessons': 'This course currently has no lessons',
    'Week': 'Week',
    'Available Toppings:': 'Available Toppings:',
    'Season Warning': 'Season WarningThis dish contains the following ingredients that are in low season on {month}',
    'Plan Dish': 'Plan Dish',
    'Start Cooking': 'Start Cooking',
    'Details': 'Details',
    'Choose New Dish': 'Choose New Dish',
    'Delete': 'Delete',
    'Delete Dish': 'Delete Dish',
    'Are you sure you want to delete this dish?': 'Are you sure you want to delete this dish?',
    'Cancel': 'Cancel',
    'Loading': 'Loading',
    'Choose Dish': 'Choose Dish',
    'Topping 1': 'Topping 1',
    'Topping 2': 'Topping 2',
    'Information': 'Information',
    'Export Shopping List': 'Export Shopping List',
    'Feedback total': 'Feedback total',
    'Ratings': 'Ratings',
    'Privacy Policy': 'Privacy Policy',
    'Previous': 'Previous',
    'Next': 'Next',
    'Manager Contact': 'Manager Contact',
    'Plan Menu': 'Plan Menu',
    'Email': 'Email',
    'required': 'required',
    'Password': 'Password',
    'Login': 'Login',
    'Forgot your password? Recover here': 'Forgot your password? Recover here',
    'Here': 'Here',
    'Dashboard': 'Dashboard',
    'Menu Planner': 'Menu Planner',
    'User Management': 'User Management',
    'Academy': 'Academy',
    'Profile': 'Profile',
    'Users': 'Users',
    'Locations': 'Locations',
    'Logout': 'Logout',
    'Register': 'Register',
    'Ready to cook?': 'Ready to cook?',
    'Dish Overview': 'Dish Overview',
    'Ingredients': 'Ingredients',
    'Basis': 'Basis',
    'Toppings': 'Toppings',
    'Overview All Steps': 'Overview All Steps',
    'Submit': 'Submit',
    'Password (confirmation)': 'Password (confirmation)',
    'Servings': 'Servings',
    'Serving': 'Serving',
    'Dish': 'Dish',
    'No ingredients available': 'No ingredients available',
    'Step': 'Step',
    'All steps': 'All steps',
    'Previous Step': 'Previous Step',
    'Done': 'Done',
    'Next Step': 'Next Step',
    'Name': 'Name',
    'Season Warning {month}': 'Season Warning {month}This dish contains the following ingredients that are in low season on {month}',
    'Season Confirmation {dish}': 'Do you want to plan {dish}?',
    'save': 'save',
    'Unassigned': 'Unassigned',
    'Back': 'Back',
    'Courses': 'Courses',
    'Hello {name}': 'Hello {name}',
    'Dish of the day': 'Dish of the day',
    'Nothing planed yet': 'Nothing planed yet',
    'CO2 Saved': 'CO2 Saved',
    'Feedback': 'Feedback',
    'Checklist': 'Checklist',
    'ForOFour.message': 'ForOFour.message',
    'ForOFour.button': 'ForOFour.button',
    'You\'ve finished the lesson': 'You\'ve finished the lesson',
    'You got': 'You got',
    'answers right': 'answers right',
    'Back to Lessons': 'Back to Lessons',
    'Your Locations': 'Your Locations',
    'Dish Bundles': 'Dish Bundles',
    'Recipe Types': 'Recipe Types',
    'Allergies': 'Allergies',
    'Sort By': 'Sort By',
    'Search': 'Search',
    'Steps': 'Steps',
    'back': 'back',
    'Overview': 'Overview',
    'Edit': 'Edit',
    'Location': 'Location',
    'Role': 'Role',
    'User updated successfully!': 'User updated successfully!',
    'There was an error processing your request.': 'There was an error processing your request.',
    'Edit User': 'Edit User',
    'Invitation sent successfully!': 'Invitation sent successfully!',
    'There was an error processing your request or this user has already been invited.': '\n\n#: src/routes/UserInvite.js src/routes/Users.js\nmsgid ',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao Bakerplate',
    'Items.header.offline': 'Ciao offline Bakerplate',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao Bakerplate',
    'Home.header.offline': 'Ciao offline Bakerplate',
  },
}
